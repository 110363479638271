
<template class="">
  <router-view></router-view>
</template>
<script>
  export default {
    name: "EmptyLayout",
    methods:{
      
    }
  }
</script>