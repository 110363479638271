<template>
   <section class="mt100 m_mt60" id="ContactUs">
      <div class="secTitle l fs40 b p10">{{ $store.state.contact_title }}</div>
      <tbl class="cw mt30">
        <cl class="vt l m_c w40 m_w50">
            <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d37489.785907447724!2d-105.56461393684613!3d53.208153328073834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2slb!4v1707673997465!5m2!1sen!2slb" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="w100"></iframe> -->
            <iframe :src="$store.state.footerData?.google_map_url" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="w100"></iframe>
        </cl>
        <cl class="vt w10 ld_w5 m_h pv10"></cl>
        <cl class="vt w40 m_w50">
          <div class="l">
            <tbl>
              <cl class="w50 vm">
                <div class="mt20">
                  <label class="customLabel" for="">First Name <span class="danger">*</span></label>
                  <input  type="text" id="contactUstext" name="First Nmae" class="ContactUsInput Vfirst_nameInput" v-model="first_name" placeholder="First Name">
                  <div class="WarningDiv Vfirst_name"></div>
                </div>
              </cl>
              <cl class="colSep30 vm"></cl>
              <cl class="w50 vm">
                <div class="mt20">
                  <label class="customLabel" for="">Last Name <span class="danger">*</span></label>
                  <input  type="text" id="contactUstext" name="Last Nmae" class="ContactUsInput Vlast_nameInput" v-model="last_name" placeholder="Last Name">
                  <div class="WarningDiv Vlast_name"></div>
                </div>
              </cl>
            </tbl>
            <div class="mt20">
              <label class="customLabel" for="">Email <span class="danger">*</span></label>
              <input  type="email" id="contactUsemail" name="Email" class="ContactUsInput VemailInput" v-model="email" placeholder="example@example.com">
              <div class="WarningDiv Vemail"></div>
            </div>
            <div class="mt20">
              <label class="customLabel" for="">Phone <span class="danger">*</span></label>
              <input  type="tel" id="contactUsPhone" name="Phone" class="ContactUsInput VphoneInput" v-model="phone" placeholder="Phone number" pattern="[0-9]*" title="Please enter only numbers">
              <div class="WarningDiv Vphone"></div>
            </div>
            <div class="mt20">
              <label class="customLabel" for="">Company Name <span class="danger">*</span></label>
              <input  type="text" id="contactUsCompanyName" name="CompanyName" class="ContactUsInput VcompanyInput" v-model="company" placeholder="Company name">
              <div class="WarningDiv Vcompany"></div>
            </div>
            <div class="mt20">
              <label class="customLabel" for="">Industry</label>
              <input  type="text" id="contactUsIndustry" name="Industry" class="ContactUsInput VindustryInput" v-model="industry" placeholder="Industry"  >
              <div class="WarningDiv Vindustry"></div>
            </div>
            <div class="mt20">
              <label class="customLabel" for="">Additional Notes</label>
              <textarea id="contactUsAdditionlNote" name="AdditionlNote" class="ContactUsInput VcommentsInput" v-model="comments" placeholder="Additional Notes"></textarea>
              <div class="WarningDiv Vcomments"></div>
            </div>
            <div class="r mt30">
              <button @click="submitForm()" class="contactUsBtn fs20">Submit</button>
            </div>
          </div>
        </cl>
      </tbl>
    </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      errors: 0,
      first_name:"",
      last_name:"",
      email:"",
      phone:"",
      company:"",
      industry:"",
      comments:"",
      disabledSubmit:false,
      numberRegex:/^\d+$/,

    };
  },
  methods:{
   ...mapActions(['showToast']),
    submitForm(){
      if(this.disabledSubmit==false){
        this.$store.state.loader=1;
        this.disabledSubmit==true;
        this.errors==0;
        this.validatedata(this.first_name, ".Vfirst_name", 'First name is required');
        this.validatedata(this.last_name, ".Vlast_name", 'Last name is required');
        this.validatedata(this.company, ".Vcompany", 'Company is required');
        // this.validatedata(this.industry, ".Vindustry", 'Industry is required');
        this.validatedata(this.comments, ".Vcomments", 'Additional Notes is required');
        if(this.email==0 || this.email==null || this.email=="" || this.email== undefined){
          $('.Vemail').html('Email is required');
          $('.Vemail').slideDown();
          $('.VemailInput').addClass('error')
          this.errors=1;
        }
        else if(this.$store.state.emailRegex.test(this.email) ==false){
          $('.Vemail').html('Please enter valid email');
          $('.Vemail').slideDown();
          $('.VemailInput').addClass('error')
          this.errors=1;
        }
        else{
          $('.Vemail').slideUp();
          $('.VemailInput').removeClass('error')
        }
        if(this.phone==0 || this.phone==null || this.phone=="" || this.phone== undefined){
          $('.Vphone').html('Phone is required');
          $('.Vphone').slideDown();
          $('.VphoneInput').addClass('error')

          this.errors=1;
        }
        else if(this.numberRegex.test(this.phone) ==false){
          $('.Vphone').html('Please enter valid Phone number');
          $('.Vphone').slideDown();
          $('.VphoneInput').addClass('error')

          this.errors=1;
        }
        else{
          $('.Vphone').slideUp();
          $('.VphoneInput').removeClass('error')
        }
        if(this.errors==0){
          var data={
              "first_name":this.first_name,
              "last_name":this.last_name,
              "email":this.email,
              "phone":this.phone,
              "company":this.company,
              "industry":this.industry,
              "comments":this.comments
          }
          this.axios.post(this.$store.state.baseApiURL + "?action=contact_request",data,
          ).then((res) => {
            if(res.data.status==1){
              this.disabledSubmit=false;
              this.$store.state.loader=0;
              this.first_name="";
              this.last_name="";
              this.email="";
              this.phone="";
              this.company="";
              this.industry="";
              this.comment="";
              this.showToast({ message: 'Contact form submited successfully.', type: 'success' })
            }
            else{
                this.showToast({ message: 'Failed to send contact us form \n Please Try again.', type: 'error' })
            }
          })
          .catch((error) => {this.showToast({ message: 'Failed to send contact us form \n Please Try again.', type: 'error' })});
          
        }
        else{
          this.disabledSubmit=false;
          this.$store.state.loader=0;
          this.showToast({ message: 'Please fill all fields.', type: 'error' })
        }

      }
    },

    validatedata(formname, validatefieldclass, text) {
      if (formname == "" || formname == null ) {
        $(validatefieldclass).html(text);
        $(validatefieldclass).slideDown();
        var inputClass=validatefieldclass+"Input";
        $(inputClass).addClass('error')
        this.errors = 1;
      } else {
        $(inputClass).removeClass('error')
        $(validatefieldclass).slideUp();
      }
    }
  },
};
</script>
<style scoped>
.customLabel{
margin-top: 20px !important;
}
</style>