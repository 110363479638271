import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import "./assets/css/mdi.css";
import "./assets/css/bea.css";
import "./assets/css/MyStyle.css";
import axios from "axios";
import VueAxios from "vue-axios";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";



const app = createApp(App).use(store).use(router);

app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false
});

app.use(VueAxios, axios);
app.mount("#app");
