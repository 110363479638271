<template>
  <div class="home" v-if="showData==1" style="min-height: 100vh;">
    <section class="pt60 pb20 c" id="Header" style="background-color: #102C22;">
      <header>
         <div class="flexi" >
            <video autoplay muted class="headerVideo" style="max-height: 75vh;">
               <source :src="$store.state.baseMediaURL+data?.banner_background" type="video/mp4">
            </video>
            <div class="ov w100 ra ba l">
               <img :src="$store.state.baseMediaURL+data?.banner_text_gif" alt="" style="max-width: 100%;">
            </div>
            <div class="ov c">
               <div class=" secTitle l h100">
               </div>
            </div>
         </div>
         <!-- <div class="c">
            <div class="flexi l">
               <div class="fs0">
                  <div class="ph5 g_nm">
                     <div class="pointer p15 round100 bgGold">
                        <span class="cc cGreen fs20 mdi mdi-linkedin"></span>
                     </div>
                  </div>
                  <div class="ph5 g_nm">
                     <div class="pointer p15 round100 bgGold">
                        <span class="cc cGreen fs20 mdi mdi-facebook"></span>
                     </div>
                  </div>
                  <div class="ph5 g_nm">
                     <div class="pointer p15 round100 bgGold">
                        <span class="cc cGreen fs20 mdi mdi-twitter"></span>
                     </div>
                  </div>
               </div>
            </div>
         </div> -->
      </header>
   </section>
   <div class="bgGreen">
      <div class="flexi">
        <section class="mt100 m_mt60" id="AboutUs">
            <tbl class="cw flipped">
               <cl class="vt w50 r">
                  <div class="secTitle l fs40 b p10">{{ data?.about_title }}</div>
                  <div class="mt30 l fs20 ld_fs18 md_fs16 m_fs15 m_m_fs14 lh30 ld_lh26 md_lh24 ph10 aboutUsDisv oh borderLeftTrans ">
                    {{ data?.about_description }}
                  </div>
                 <router-link :to="{ name: 'about'}"><button class="mt30 readMoreAboutBtn">Read More</button></router-link>
               </cl>
               <cl class="vt colSep40 pv10"></cl>
               <cl class="vt w50">
                  <img :src="$store.state.baseMediaURL+data?.about_image" alt="" class="w100">
               </cl>
            </tbl>
         </section>
         <section class="mt100 m_mt60" id="WithEsseTrack">
            <div class="secTitle l fs40 b p10">{{ data?.section2_title }}</div>
            <tbl class="cw ">
               <cl class="vt l w50">
                  <img :src="$store.state.baseMediaURL+data?.section2_image" alt="" class="w100" style="max-width: 280px;">
               </cl>
               <cl class="vt colSep40 pv10"></cl>
               <cl class="vt w50">
                  <div class="l fs20 ld_fs18 md_fs16 m_fs15 m_m_fs14 lh30 ld_lh26 md_lh24 ph10 borderLeftTrans">
                     <p id="withETPara_1" class="withETPara">Transform your business operations by streamlining data management for increased efficiency.</p>
                     <p id="withETPara_2" class="withETPara mt10">Amplify insights through custom reporting, mitigating risks with robust security measures.</p>
                     <p id="withETPara_3" class="withETPara mt10">Empower your business to make informed, agile decisions while optimizing operational processes.</p>
                     <p id="withETPara_4" class="withETPara mt10">Enhance your business with a versatile, secure, and collaborative environment.</p>
                     <p id="withETPara_5" class="withETPara mt10">Enhance and optemize your system efficiency to drive productivity and effectiveness.</p>
                  </div>
               </cl>
            </tbl>
         </section>
         <section class="mt100 m_mt60" id="ESSETRACKDescription">
            <div class="m_h DescriptionDIv">
               <tbl_nm>
                  <rw class="colSepRow" id="colSep_1">
                     <cl class="w50 pv10 m_w30 vt r">
                        <div class="ov ba la colSepArrow"></div>
                        <p class="pr90" style="margin-top: 13%;"><span class="vd fs40 md_fs34">{{ data?.section3_text1 }}</span> <span class="vd ml10 fs60">{{ data?.section3_text1.substr(0, 1) }}</span></p>
                        <p class="fs18 lh20 m_fs16 l ph10 mt8">{{ data?.section3_desc1 }}</p>
                     </cl>
                     <cl class="colSep5 bgw">
                        <div class="cc oh p25 round100 bgw z100" style="top: 40%;">
                           <div class="cc p8 bgGold round100"><div class="cc p4 bgw round100"></div></div>
                        </div>
                     </cl>
                     <cl class="w50 pv10 vt pl50 m_w70">
                        <div class="ph50 ld_ph30 md_ph15">
                           <tbl_nm>
                              <cl class="w50 bgGold">
                                 <div class="cc fs70 cw">01</div>
                              </cl>
                              <cl class="colSep"></cl>
                              <cl class="w50">
                                 <img :src="$store.state.baseMediaURL+data?.section3_img1" alt="" class="w100">
                              </cl>
                           </tbl_nm>
                        </div>
                     </cl>
                  </rw>
                  <rw class="colSepRow" id="colSep_2">
                     <cl class="w50 pv10 vt pr50 m_w70">
                        <div class="ph50 ld_ph30 md_ph15">
                           <tbl_nm>
                              <cl class="w50">
                                 <img :src="$store.state.baseMediaURL+data?.section3_img2" alt="" class="w100">
                              </cl>
                              <cl class="colSep"></cl>
                              <cl class="w50 bgBrown">
                                 <div class="cc fs70 cw">02</div>
                              </cl>
                           </tbl_nm>
                        </div>
                     </cl>
                     <cl class="colSep5 bgw">
                        <div class="cc oh p25 round100 bgw z100" style="top: 40%;">
                           <div class="cc p8 bgGold round100"><div class="cc p4 bgw round100"></div></div>
                        </div>
                     </cl>
                     <cl class="w50 pv10 vt l m_w30">
                        <div class="ov ba ra colSepArrow"></div>
                        <p class="pl90" style="margin-top: 13%;"><span class="vd mr10 fs60">{{ data?.section3_text2.substr(0, 1) }}</span> <span class="vd fs40 md_fs34">{{ data?.section3_text2 }}</span> </p>   
                        <p class="fs18 lh20 m_fs16 l ph10 mt8">{{ data?.section3_desc2 }}</p>
                     </cl>
                  </rw>
                  <rw class="colSepRow" id="colSep_3">
                     <cl class="w50 pv10 vt m_w30 r">
                        <div class="ov ba la colSepArrow"></div>
                        <p class="pr90" style="margin-top: 13%;"><span class="vd fs40 md_fs34">{{ data?.section3_text3 }}</span> <span class="vd ml10 fs60">{{ data?.section3_text3.substr(0, 1) }}</span></p>
                        <p class="fs18 lh20 m_fs16 l ph10 mt8">{{ data?.section3_desc3 }}</p>
                     </cl>
                     <cl class="colSep5 bgw">
                        <div class="cc oh p25 round100 bgw z100" style="top: 40%;">
                           <div class="cc p8 bgGold round100"><div class="cc p4 bgw round100"></div></div>
                        </div>
                     </cl>
                     <cl class="w50 pv10 vt pl50 m_w70">
                        <div class="ph50 ld_ph30 md_ph15">
                           <tbl_nm>
                              <cl class="w50 bgGold">
                                 <div class="cc fs70 cw">03</div>
                              </cl>
                              <cl class="colSep"></cl>
                              <cl class="w50">
                                 <img :src="$store.state.baseMediaURL+data?.section3_img3" alt="" class="w100">
                              </cl>
                           </tbl_nm>
                        </div>
                     </cl>
                  </rw>
                  <rw class="colSepRow" id="colSep_4">
                     <cl class="w50 pv10 vt pr50 m_w70">
                        <div class="ph50 ld_ph30 md_ph15">
                           <tbl_nm>
                              <cl class="w50">
                                 <img :src="$store.state.baseMediaURL+data?.section3_img4" alt="" class="w100">
                              </cl>
                              <cl class="colSep"></cl>
                              <cl class="w50 bgBrown">
                                 <div class="cc fs70 cw">04</div>
                              </cl>
                           </tbl_nm>
                        </div>
                     </cl>
                     <cl class="colSep5 bgw">
                        <div class="cc oh p25 round100 bgw z100" style="top: 40%;">
                           <div class="cc p8 bgGold round100"><div class="cc p4 bgw round100"></div></div>
                        </div>
                     </cl>
                     <cl class="w50 pv10 vt l m_w30">
                        <div class="ov ba ra colSepArrow"></div>
                        <p class="pl90" style="margin-top: 13%;"><span class="vd mr10 fs60">{{ data?.section3_text4.substr(0, 1) }}</span> <span class="vd fs40 md_fs34">{{ data?.section3_text4 }}</span> </p> 
                        <p class="fs18 lh20 m_fs16 l ph10 mt8">{{ data?.section3_desc4 }}</p>
                     </cl>
                  </rw>
               </tbl_nm>
            </div>
            <div class="h m_sb DescriptionDIv">
               <tbl_nm>
                  <rw class="colSepRow" id="m_colSep_1">
                     <cl class="" style="width: 20px;"></cl>
                     <cl class="colSep5 bgw">
                        <div class="cc oh p25 round100 bgw z100" style="top: 24%;">
                           <div class="cc p8 bgGold round100"><div class="cc p4 bgw round100"></div></div>
                        </div>
                     </cl>
                     <cl class="pt30 pl60">
                        <div class="ov ba ra m_colSepArrow colSepArrow"></div>
                        <p class="l"><span class="vd mr10 fs40">{{ data?.section3_text1.substr(0, 1) }}</span><span class="vd fs30">{{ data?.section3_text1 }}</span> </p>
                        <p class="fs18 lh20 m_fs16 l ph10 mt15">{{ data?.section3_desc1 }}</p>
                        <tbl_nm class="mt50">
                           <cl class="w50 bgGold">
                              <div class="cc fs70 cw">01</div>
                           </cl>
                           <cl class="colSep"></cl>
                           <cl class="w50">
                              <img :src="$store.state.baseMediaURL+data?.section3_img1" alt="" class="w100">
                           </cl>
                        </tbl_nm>
                     </cl>
                  </rw>
                  <rw class="colSepRow" id="m_colSep_2">
                     <cl class="" style="width: 20px;"></cl>
                     <cl class="colSep5 bgw">
                        <div class="cc oh p25 round100 bgw z100" style="top: 24%;">
                           <div class="cc p8 bgGold round100"><div class="cc p4 bgw round100"></div></div>
                        </div>
                     </cl>
                     <cl class="pt30 pl60">
                        <div class="ov ba ra m_colSepArrow colSepArrow"></div>
                        <p class="l"><span class="vd mr10 fs40">{{ data?.section3_text2.substr(0, 1) }}</span><span class="vd fs30">{{ data?.section3_text2 }}</span> </p>
                        <p class="fs18 lh20 m_fs16 l ph10 mt15">{{ data?.section3_desc2 }}</p>
                        <tbl_nm class="mt50">
                           <cl class="w50 bgBrown">
                              <div class="cc fs70 cw">02</div>
                           </cl>
                           <cl class="colSep"></cl>
                           <cl class="w50">
                              <img :src="$store.state.baseMediaURL+data?.section3_img2" alt="" class="w100">
                           </cl>
                        </tbl_nm>
                     </cl>
                  </rw>
                  <rw class="colSepRow" id="m_colSep_3">
                     <cl class="" style="width: 20px;"></cl>
                     <cl class="colSep5 bgw">
                        <div class="cc oh p25 round100 bgw z100" style="top: 24%;">
                           <div class="cc p8 bgGold round100"><div class="cc p4 bgw round100"></div></div>
                        </div>
                     </cl>
                     <cl class="pt30 pl60">
                        <div class="ov ba ra m_colSepArrow colSepArrow"></div>
                        <p class="l"><span class="vd mr10 fs40">{{ data?.section3_text3.substr(0, 1) }}</span><span class="vd fs30">{{ data?.section3_text3 }}</span> </p>
                        <p class="fs18 lh20 m_fs16 l ph10 mt15">{{ data?.section3_desc3 }}</p>
                        <tbl_nm class="mt50">
                           <cl class="w50 bgGold">
                              <div class="cc fs70 cw">03</div>
                           </cl>
                           <cl class="colSep"></cl>
                           <cl class="w50">
                              <img :src="$store.state.baseMediaURL+data?.section3_img3" alt="" class="w100">
                           </cl>
                        </tbl_nm>
                     </cl>
                  </rw>
                  <rw class="colSepRow" id="m_colSep_4">
                     <cl class="" style="width: 20px;"></cl>
                     <cl class="colSep5 bgw">
                        <div class="cc oh p25 round100 bgw z100" style="top: 24%;">
                           <div class="cc p8 bgGold round100"><div class="cc p4 bgw round100"></div></div>
                        </div>
                     </cl>
                     <cl class="pt30 pl60">
                        <div class="ov ba ra m_colSepArrow colSepArrow"></div>
                        <p class="l"><span class="vd mr10 fs40">{{ data?.section3_text4.substr(0, 1) }}</span><span class="vd fs30">{{ data?.section3_text4 }}</span> </p>
                        <p class="fs18 lh20 m_fs16 l ph10 mt15">{{ data?.section3_desc4 }}</p>
                        <tbl_nm class="mt50">
                           <cl class="w50 bgBrown">
                              <div class="cc fs70 cw">04</div>
                           </cl>
                           <cl class="colSep"></cl>
                           <cl class="w50">
                              <img :src="$store.state.baseMediaURL+data?.section3_img4" alt="" class="w100">
                           </cl>
                        </tbl_nm>
                     </cl>
                  </rw>

                
               </tbl_nm>
            </div>
         </section>
         <section class="mt100 m_mt60" id="OurFeatures">
            <div class="secTitle l fs40 b p10">{{ data?.features_title }}</div>
            <div class="mt100 m_mt60 c fs0">
               <div v-if="featuresList.length>0" v-for="(item, index) in featuresList" class="g_nm featureBox borderGoldBottom borderGoldRight vm l p10 w20 ld_w25 m_w33 m_w40 m_m_w50">
                  <div class="pointer" @click="showFeature(item.id)">
                     <tbl_nm class="featureTbale">
                        <cl class="w16 vm" style="min-width: 30px;">
                           <img :src="$store.state.baseMediaURL+item.icon" :alt="item.title" class="w100 featureImage">
                        </cl>
                        <cl class="ph5 wa vm featureText b">{{ item.title }}</cl>
                     </tbl_nm>
                  </div>
               </div>
            </div>
         </section>
         <section class="mt100 m_mt60 l" id="FeaturesDetails">
            <div class="featureDetailsBox">
               <div class="fs30 m_fs20 b featureName">
                  <div class="pv5">{{selectedFeature?.title}}</div>
                  <div class="featureGoldLine"></div>
               </div>
               <tbl class="mt30">
                  <cl class="w50 vm">
                     <img src="@/assets/img/GoldSquare.svg" alt="" style="width: 70px;">
                     <div class="fs20 ld_fs18 md_fs16 m_fs15 m_m_fs14 lh30 ld_lh26 md_lh24 featureDescription">{{selectedFeature?.description}}</div>
                     <div class="mt50 c">
                        <a href="#OurFeatures"><div class="g_nm pointer">
                           <img src="@/assets/img/TopIcon.png" alt="" style="width: 20px;">
                           <div class="mt8 cGold fs20 m_fs18 b">Back to Top</div>
                        </div></a>
                     </div>
                  </cl>
                  <cl class="colSep pv10 vm "></cl>
                  <cl class="w50 vm c"><img :src="$store.state.baseMediaURL+selectedFeature.image" alt="" class="w100"></cl>
               </tbl>
            </div>
         </section>
         <ContactUs/>
      </div>
    </div>
  </div>
</template>

<script>
import ContactUs from '@/components/ContactUs.vue';

export default {
name: 'HomeView',
components: {ContactUs},
data() {
  return {
    data:{},
    featuresList:[],
    selectedFeature:{},
    showData:0,
  }
},
async created() {

  await this.getData();
},
mounted(){
  $(window).scroll(function() {
    var scrollPosition = $(this).scrollTop();
    // Define the percentage from the top (e.g., 20%)
    var triggerPoint = $(window).height() * 0.3;
    var triggerPointTextMax = $(window).height() * 0.4;
    var triggerPointTextMin = $(window).height() * 0.3;
    if ($('#colSep_1').length) { var colSep_1 = $('#colSep_1').offset().top; } else {var colSep_1 = 1000; }
    if ($('#colSep_2').length) { var colSep_2 = $('#colSep_2').offset().top; } else {var colSep_2 = 1000; }
    if ($('#colSep_3').length) { var colSep_3 = $('#colSep_3').offset().top; } else {var colSep_3 = 1000; }
    if ($('#colSep_4').length) { var colSep_4 = $('#colSep_4').offset().top; } else {var colSep_4 = 1000; }
    if ($('#m_colSep_1').length) { var m_colSep_1 = $('#m_colSep_1').offset().top; } else {var m_colSep_1 = 1000; }
    if ($('#m_colSep_2').length) { var m_colSep_2 = $('#m_colSep_2').offset().top; } else {var m_colSep_2 = 1000; }
    if ($('#m_colSep_3').length) { var m_colSep_3 = $('#m_colSep_3').offset().top; } else {var m_colSep_3 = 1000; }
    if ($('#m_colSep_4').length) { var m_colSep_4 = $('#m_colSep_4').offset().top; } else {var m_colSep_4 = 1000; }
    if ($('#withETPara_1').length) { var withETPara_1 = $('#withETPara_1').offset().top; } else {var withETPara_1 = 1000; }
    if ($('#withETPara_2').length) { var withETPara_2 = $('#withETPara_2').offset().top; } else {var withETPara_2 = 1000; }
    if ($('#withETPara_3').length) { var withETPara_3 = $('#withETPara_3').offset().top; } else {var withETPara_3 = 1000; }
    if ($('#withETPara_4').length) { var withETPara_4 = $('#withETPara_4').offset().top; } else {var withETPara_4 = 1000; }
    if ($('#withETPara_5').length) { var withETPara_5 = $('#withETPara_5').offset().top; } else {var withETPara_5 = 1000; }
    if (colSep_1 - scrollPosition < triggerPoint) {$('#colSep_1').addClass('active');} else {$('#colSep_1').removeClass('active');}
    if (colSep_2 - scrollPosition < triggerPoint) {$('#colSep_2').addClass('active');} else {$('#colSep_2').removeClass('active');}
    if (colSep_3 - scrollPosition < triggerPoint) {$('#colSep_3').addClass('active');} else {$('#colSep_3').removeClass('active');}
    if (colSep_4 - scrollPosition < triggerPoint) {$('#colSep_4').addClass('active');} else {$('#colSep_4').removeClass('active');}
    if (m_colSep_1 - scrollPosition < triggerPoint) {$('#m_colSep_1').addClass('active');} else {$('#m_colSep_1').removeClass('active');}
    if (m_colSep_2 - scrollPosition < triggerPoint) {$('#m_colSep_2').addClass('active');} else {$('#m_colSep_2').removeClass('active');}
    if (m_colSep_3 - scrollPosition < triggerPoint) {$('#m_colSep_3').addClass('active');} else {$('#m_colSep_3').removeClass('active');}
    if (m_colSep_4 - scrollPosition < triggerPoint) {$('#m_colSep_4').addClass('active');} else {$('#m_colSep_4').removeClass('active');}
    if ((withETPara_1 - scrollPosition < triggerPointTextMax)&& withETPara_1 - scrollPosition > triggerPointTextMin) {$('#withETPara_1').addClass('active');} else {$('#withETPara_1').removeClass('active');}
    if ((withETPara_2 - scrollPosition < triggerPointTextMax)&& withETPara_2 - scrollPosition > triggerPointTextMin) {$('#withETPara_2').addClass('active');} else {$('#withETPara_2').removeClass('active');}
    if ((withETPara_3 - scrollPosition < triggerPointTextMax)&& withETPara_3 - scrollPosition > triggerPointTextMin) {$('#withETPara_3').addClass('active');} else {$('#withETPara_3').removeClass('active');}
    if ((withETPara_4 - scrollPosition < triggerPointTextMax)&& withETPara_4 - scrollPosition > triggerPointTextMin) {$('#withETPara_4').addClass('active');} else {$('#withETPara_4').removeClass('active');}
    if ((withETPara_5 - scrollPosition < triggerPointTextMax)&& withETPara_5 - scrollPosition > triggerPointTextMin) {$('#withETPara_5').addClass('active');} else {$('#withETPara_5').removeClass('active');}
});
      
},
methods:{
  async getData() {
    this.$store.state.pageLoader=1;
    this.axios.get(this.$store.state.baseApiURL + "?action=homepage",
    ).then((res) => {
      if(res.data.status==1){
        this.data=res.data.data;
      //   this.showData=1;
      //   this.$store.state.pageLoader=0;
      }
    }).then(()=>{
      this.axios.get(this.$store.state.baseApiURL + "?action=features",
      ).then((res) => {
         if(res.data.status==1){
         this.featuresList=res.data.data;
         if(this.featuresList.length>0){
            this.selectedFeature=this.featuresList[0];
         }
         this.showData=1;
         this.$store.state.pageLoader=0;
         }
      })
      .catch((error) => {console.log("Failed to fetch data")});
    })
    .catch((error) => {console.log("Failed to fetch data")});
   
    
  },
  showFeature(idVal){
    var index=this.featuresList.findIndex(item => item.id==idVal)
    if(index !=-1){
      this.selectedFeature=this.featuresList[index]
      $('html, body').animate({
        scrollTop: $('#FeaturesDetails').offset().top -100
      }, 100);
    }
    
    
  }
}
}
</script>
