import { createStore } from 'vuex'
import { useToast } from 'vue-toastification'

const toast = useToast()

export default createStore({

  state: {
    loader:0,
    pageLoader:0,
    baseApiURL:"https://essetrack.com/cms/api.php",
    baseMediaURL:"https://essetrack.com/images/",
    footerData:{},
    emailRegex: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    footer_about_title:"",
    footer_about_text:"",
    contact_title:"",
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    showToast({ commit }, { message, type }) {
      switch (type) {
        case 'success':
          toast.success(message)
          break
        case 'error':
          toast.error(message)
          break
        case 'warning':
          toast.warning(message)
          break
        case 'error':
          toast.error(message)
          break
        default:toast(message)
          break
      }
    }
  },
  modules: {
  }
})
