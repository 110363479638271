import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/features",
    name: "features",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/FeaturesView.vue"),
  },
  {
    path: "/industries",
    name: "industries",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/IndustriesView.vue"),
  },
  {
    path: "/about",
    name: "about",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    meta: {
      layout: "WebLayout",
    },
    component: () => import("../views/ContactUsView.vue"),
  },
  



]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
